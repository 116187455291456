import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import Header from '../components/HeaderWhite/header'
import Footer from '../components/Footer/footer'
import IntegrationAsana from '../components/PersonalIntegrations/asanaPage'
import { Helmet } from "react-helmet";

// const content = `Track time in Asana tasks with DueFocus. Boost your and your teams efficience with advanced tools.`
const content = `Duefocus is a free time tracking software that enables tracking time with timesheets and sends advanced reports`

const Asana = () => (
  <>
    <Helmet>
      <html lang="en" />
      <meta charSet="utf-8" />
      <title>Asana Integration with DueFocus Time Tracking Software</title>
      <meta name="description" content={content} />
      <meta name="robots" content="index, follow" />
      <link rel="canonical" href="https://www.duefocus.com/time-tracking-asana/" />
    </Helmet>
    <Header />
    <IntegrationAsana />
    <Footer />
  </>
)

export default Asana